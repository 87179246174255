// src/pages/index.js

import React from "react"
import posed from "react-pose"
import { Grid, Row, Col } from 'react-flexbox-grid';

import SEO from "../components/seo"

import Button from "../components/Button"
import {
  StyledSection,
  SectionIntro,
  IntroSubtitle,
  buttonWrapper,
  IntroHeader,
  StyledSubheader,
  SectionContentBlock,
  IntroContent,
  ExperienceTitle,
  ExperienceSub,
  SocialLink,
} from "../pageStyles/pageStyles"
import styled from "styled-components"

const IconMore = (<svg width="11" height="11" xmlns="http://www.w3.org/2000/svg">
  <g stroke="#000" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
    <path d="M5.629 1v9M1 5.629h9"/>
  </g>
</svg>)
const IconLess = (<svg width="11" height="3" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1.629h9" stroke="#000" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"
        strokeLinejoin="round"/>
</svg>)

// const AnimatedHeader = styled(
//   posed.h1({
//     open: {
//       opacity: 1,
//       delayChildren: 500,
//       staggerChildren: 100,
//     },
//     closed: {
//       opacity: 0
//     },
//   })
// )({
//   overflow: 'hidden',
//   fontSize: '48px',
//   lineHeight: '64px',
//   letterSpacing: '-0.011em',
//   opacity: 0, // Add this to stop flash.
// });
//
// const DelayedItem = posed.div({
//   open: {
//     y: 0,
//     opacity: 1,
//     transition: ({ duration }) => ({ duration: duration, ease: [0.075, 0.82, 0.165, 1]})
//   },
//   closed: {
//     // y: 55,
//     opacity: 0,
//
//     transition: {
//       transition: ({ duration }) => ({ duration: duration, ease: [0.6, 0.04, 0.98, 0.335]})
//     },
//   },
// })
//
// const AnimationWrapper = posed.div({
//   open: {
//     delayChildren: 1000,
//     staggerChildren: 100,
//   },
// })
// const DelayedContentAnim = posed.div({
//   open: {
//     // y: 0,
//     opacity: 1,
//     transition: {
//       duration: 4000,
//       ease: [0.075, 0.82, 0.165, 1],
//     },
//   },
//   closed: {
//     // y: 50,
//     opacity: 0,
//     transition: {
//       duration: 4000,
//       ease: [0.6, 0.04, 0.98, 0.335],
//     },
//   },
// })


class IndexPage extends React.Component {

  state = {
    sectionExpanded: false,
    isOpen: false,
  }

  componentDidMount() {
    setTimeout(this.toggle, 100)
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })

  render() {
    const { isOpen } = this.state

    return (
      <div>
        <SEO title="Home"/>
        {/*<AdventureLandBrand />*/}
        <Grid fluid>
          <Row between="xs">
            <Col xs={12}>
              <IntroHeader>
                Hello, I’m Sudeep, <br/> A Product Designer based in Bangalore, IND.
              </IntroHeader>

              <IntroContent>
                <StyledSubheader>
                  About
                </StyledSubheader>
                <SectionIntro>I’m an experienced Interaction Designer, UX Designer, Design Technologist, Design System
                  Architect, a passion for prototyping, in-browser design, motion design, accessibility, and
                  performance.</SectionIntro>
                <div style={buttonWrapper}>
                  <Button secondary onClick={() => this.setState({ sectionExpanded: !this.state.sectionExpanded })}>
                    Know More&nbsp;
                    {this.state.sectionExpanded ? IconLess : IconMore}
                  </Button>
                </div>
              </IntroContent>
            </Col>
          </Row>
        </Grid>


        {this.state.sectionExpanded && (
          <Grid fluid>
            <Row between="xs">
              <Col xs={12}>
                <IntroSubtitle>
                  <SectionContentBlock>
                    I believe that great UI development is as much an act of design as it is engineering. This work should be
                    done through direct, constant collaboration between designers and engineers. This does not mean drawing a
                    picture of a site in a comping tool and handing off specs to an engineer, but building the design out of
                    the material through which a user will experience it (code).
                  </SectionContentBlock>

                  <SectionContentBlock>
                    I excel at decomposing designs into reusable modules and assembling a flexible, scalable design system. I
                    love the fit and finish work that ensures that the design intent makes it to the user in a way that takes
                    advantage of everything the user’s device can provide.
                  </SectionContentBlock>
                </IntroSubtitle>
              </Col>

            </Row>

          </Grid>
        )}


        <StyledSection>
          <Grid fluid>
            <StyledSubheader>
              Work Experiences
            </StyledSubheader>
          </Grid>
          <Grid fluid>
            <Row between="xs">
              <Col xs={12} sm={4}>
                <ExperienceTitle>Sensibull</ExperienceTitle>
                <ExperienceSub>Product Designer <br/> 2018-Present</ExperienceSub>
              </Col>
              <Col xs={12} sm={4}>
                <ExperienceTitle>qplum</ExperienceTitle>
                <ExperienceSub>UX Designer + UX Engineer <br/> 2016-2018</ExperienceSub>
              </Col>
              <Col xs={12} sm={4}>
                <ExperienceTitle>Mobikon</ExperienceTitle>
                <ExperienceSub>UX Engineer <br/> 2014-2016</ExperienceSub>
              </Col>
            </Row>
            <Row between="xs">
              <Col xs={12} sm={4}>
                <ExperienceTitle>Verayu (Consultant)</ExperienceTitle>
                <ExperienceSub>UX Engineer <br/> 2014</ExperienceSub>
              </Col>
              <Col xs={12} sm={4}>
                <ExperienceTitle>Parallellabs</ExperienceTitle>
                <ExperienceSub>UX Engineer <br/> 2014</ExperienceSub>
              </Col>
              <Col xs={12} sm={4}>
                <ExperienceTitle>Radiance Design Studio</ExperienceTitle>
                <ExperienceSub>Full Stack Developer<br/> 2012-2014</ExperienceSub>
              </Col>
            </Row>
          </Grid>
        </StyledSection>
        <StyledSection>
          <Grid fluid>
            <StyledSubheader>
              Online
            </StyledSubheader>
          </Grid>
          <Grid fluid>
            <Row between="xs">
              <Col xs={4}>
                <SocialLink href={'https://linkedin.com/in/sudeepmp'} target='_blank'>LinkedIn</SocialLink>
                <SocialLink href={'https://twitter.com/adventurelandio'} target='_blank'>Twitter</SocialLink>
              </Col>
              <Col xs={4} >
                <SocialLink href={'https://github.com/sharpshooter90'} target='_blank'>Github</SocialLink>
                <SocialLink href={'http://codepen.io/sharpshooter/'} target='_blank'>Codepen</SocialLink>
              </Col>
              <Col xs={4} >
                <SocialLink href={'https://www.facebook.com/sudeep.melepunathil'} target='_blank'>Facebook</SocialLink>
                <SocialLink href={'https://www.instagram.com/sudeep_mp'} target='_blank'>Instagram</SocialLink>
              </Col>
            </Row>
          </Grid>
        </StyledSection>

      </div>
    )
  }
}

export default IndexPage